@import "froala_blocks.min.scss";

$brand-primary: #fe0000;
$headings-font-family: Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
$header: 138.61px;
$header-mobile: 112.4px;

html {
  scroll-padding-top: $header-mobile;

  @media screen and (min-width: 992px) {
    scroll-padding-top: $header;
  }
}

body {
  font-family: $headings-font-family;
  min-height: 100vh;
  padding-top: 85px;

  @media screen and (min-width: 992px) {
    padding-top: 110px;
  }
}

p.lead {
  font-weight: 400;
  font-size: 1rem;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6, .fdb-block .text-h1, .fdb-block h1, .fdb-block .h1 {
  font-family: $headings-font-family;
  font-weight: 700 !important;
}

.fdb-block .text-h1, .fdb-block h1 {
  font-weight: 400;
  font-size: 28px;

  @media screen and (min-width: 481px){
    font-size: 36px;
  }

  @media screen and (min-width: 992px) {
    font-size: 44px;
  }
}

.logo img {
  width: 90px;
  height: auto;

  @media screen and (min-width: 992px) {
    width: 125px;
  }
}

.lead {
  @media screen and (max-width: 991px) {
    font-size: 1.125rem;
  }
}

.fdb-block {
  color: #333;

  @media screen and (max-width: 991px) {
    padding: 80px 0;
  }
}

header {
  padding: 5px 0;
  background-color: rgba(255,255,255,0.5);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(255,255,255,0.5);

  & > .container {
    @media screen and (max-width: 992px) {
      max-width: none;
    }
  }

  & .navbar {
    padding-top: 0;
    padding-bottom: 0;

    & .navbar-collapse {
      justify-content: flex-end;
      @media screen and (max-width: 992px) {
        padding-bottom: 20px;
      }
    }
  }

  .navbar-nav {

    & .active {
      & > a.nav-link {
        color: $brand-primary;

        &::after {
          opacity: 1;
        }
      }
    }

    & > li > a.active {
      color: $brand-primary;

      &::after {
        opacity: 1;
      }
    }

    & > li {

      @media screen and (max-width: 991px) {
        padding-left: 0px;
      }
    }

    a.nav-link {
      color: #222;
      font-weight: 700;
      font-size: 15px;
      font-family: $headings-font-family;
      position: relative;
      padding: 0.5rem 1rem !important;
      text-align: center;

      @media screen and (max-width: 991px) {
        text-transform: none;
        font-size: 14px;
        padding: 0.5rem 0 !important;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: $brand-primary;
        opacity: 0;

        @media screen and (max-width: 991px) {
          left: -10px;
          right: initial;
          bottom: initial;
          top: 50%;
          transform: translateY(-50%);
          margin: initial;
        }
      }
      &:hover {
        color: $brand-primary;
      }
    }
  }
}

.lead {
  &-sm {
    font-size: 16px;
    line-height: 1.75;
  }
}

.band-tint {
  background-color: #f2f2f2;
}

.brand-title {
  position: relative;
  display: inline-block;
  padding: 0 60px;
  text-transform: uppercase;

  @media screen and (max-width: 991px) {
    padding: 0 30px;
  }

  &::after,
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 6px;
    background-color: $brand-primary;
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }
}

.masthead-intro {
  position: relative;
  padding: 200px 0 120px 0;
  background-position: top center;
  color: #222;
  height: 100vh;
  top: -85px;
  margin-bottom: -85px;

  display: flex;
  justify-content: center;
  align-items: center;

   @media screen and (min-width: 992px) {
     background-attachment: fixed;
     margin-bottom: -113px;
     top: -113px;
   }

  & > .container {
    position: relative;
    z-index: 10;
  }

  & .lead {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.75;

    @media screen and (min-width: 992px) {
      font-size: 24px;
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to top, rgba(255, 255, 255, 0) 5%, rgb(255 172 172 / 65%));
  }
}

button.navbar-toggler {
  border: none;
  color:#222
}
.masthead-intro .masthead-title {
  font-size: 45px;
  font-weight: 400;

  @media screen and (min-width: 768px) {
    font-size: 50px;
  }

  @media screen and (min-width: 992px) {
    font-size: 60px;
  }
}

.masthead-internal {
  padding: 100px 0;

  & .block-title {
    font-size: 30px;
    font-weight: 700;
    color: #fff;

    @media screen and (min-width: 768px) {
      font-size: 40px;
    }

    @media screen and (min-width: 992px) {
      font-size: 50px;
    }
  }

  .breadcrumb {
    background-color: transparent;
    padding: 0;
    margin-bottom: 0;


    .breadcrumb-item + .breadcrumb-item::before {
      color: #fff;
    }

    & > li {
      font-size: 16px;
      font-weight: 400;
      color: #fff;

      @media screen and (min-width: 768px) {
        font-size: 18px;
      }

      @media screen and (min-width: 992px) {
        font-size: 21px;
      }

      & > a {
        color: #fff;
      }
    }
  }
}


.btn-primary {
  background-color: $brand-primary;
  border: 0;
  border-radius: 50px;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: 700;
  font-family: $headings-font-family;

  &:hover,
  &:active,
  &:focus {
    background-color: darken($color: $brand-primary, $amount: 5);
  }
}


.icon-circle {
  width: 80px;
  height: 80px;
  background-color: #f2f2f2;
  box-shadow: 0 0 0 2px white, 0 0 0 4px #f2f2f2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    right: -30px;
    height: 4px;
    background-color: #f2f2f2;
    width: 26px;
    display: none;
  }
}

.link-text {
  color: $brand-primary;
  box-shadow: 0 1px $brand-primary;
  &:hover,
  &:focus {
    text-decoration: none;
    color: darken($brand-primary, 5%);
  }
}

.mision-block {
  text-align: center;

  & .icon-circle {
    margin: auto;
  }
  & h4 {
    font-size: 30px;
    font-weight: 400;
  }
}

#contacto {
  background: linear-gradient(0deg, #fffcf1 0%, #fff2d9 100%);

}
#marcas {
  background: linear-gradient(63.34deg, #ffe0e0 0%, #ffe9e9 100%);
  h1 {
    color: #222;
  }

  ul.logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    li {
      border-radius: 6px;
      padding:8px;
      background-color: #fff;
      width: 170px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin:12px;
      @media screen and (max-width: 991px) {
        width: 43%;
      }
      img {
        display: block;
        width: auto;
        max-width: 100%;
      }
    }
  }


}

footer {
  border-top: 1px solid #CCC;
}

.fdb-block .fdb-touch {
  border-color: $brand-primary;
}

.product-grid > [class^='col-'] {
  margin-bottom: 30px;
  display: flex;
}

.product-card,
%product-card {
  background-color: #fff;
  box-shadow: 0 0 30px rgba(0,0,0,.075);
  border-radius: 3px;
  overflow: hidden;

  flex: 1;
  display: flex;
  flex-direction: column;

  & .product-grid-item-content {
    padding: 15px;
  }

  .product-grid-item-header {
    margin-bottom: 15px;
    border-bottom: 0;
  }

  & .product-title {
    font-size: 18px;
    font-weight: 700;
    margin-top: 0;
    color: #333;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }

  .product-grid-item-marca {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1px;
    font-family: $headings-font-family;
    display: inline-block;
    padding: 5px 8px;
    background-color: #fe0000;
    border-radius: 2px;
    color: white;
    position: absolute;
    left: 0;
    top: 0;
  }

  .product-grid-item-media {
    border-bottom: 1px solid #eee;
    position: relative;
  }

  .product-grid-item-footer {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #eee;
  }

  .product-grid-item-categories {
    display: flex;
    margin-bottom: 5px;

    &>li {
      font-size: 14px;
      color: #777;
      font-weight: 700;
    }
  }

  & img {
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.product-grid-item {
  @extend %product-card;
  border: 1px solid #eee;

  & > a {
    display: block;

    &:hover,
    &:focus {
      text-decoration: none;
      color: $brand-primary;
    }
  }

  & .product-grid-item-content {
    & p {
      font-size: 14px;
      line-height: 1.75;
      color: #777;
    }
  }
}

.product-item-category {
  @extend %product-card;

  & > a {
    color: inherit;
    text-decoration: none;

    &:hover,
    &:focus {
      color: inherit;
    }
  }

  & h3 {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    margin-bottom: 10px;
    padding-bottom: 10px;

    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 20px;
      height: 2px;
      background-color: $brand-primary;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}

.productos-home > .container {

  @media screen and (max-width: 1200px) and (min-width: 992px) {
    max-width: none;
  }
}



.product-full {

  & .product-full-title {
    font-weight: 700;
    font-size: 30px;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }


  & .lead {
    line-height: 1.75;
    font-size: 18px;
    font-weight: 400;
    color: #777;
    font-family: $headings-font-family;
  }

  & .product-grid-item-categories {
    display: flex;
    margin-bottom: 5px;

    &>li {
      font-size: 14px;
      color: #777;
      font-weight: 700;
    }
  }

  .product-grid-item-marca {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    font-family: $headings-font-family;
    display: inline-block;
    padding: 5px 8px;
    background-color: #fe0000;
    border-radius: 2px;
    color: white;
  }
}


.product-full-slide {
  margin-bottom: 0;

  & > .slides {
    margin: 0 -15px;
    padding-top: 0;
    padding-bottom: 0;
  }

  & .slick-track {
    display: flex;
    flex-wrap: wrap;
  }

  & .custom-slide-item {
    padding: 0 15px;
    margin-bottom: 15px;
    float: none;

    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    height: auto;
    align-items: center;
    justify-content: center;

    & img {
      width: auto;
      height: auto;

      max-width: 100%;
      max-height: 100%;

      left: 0;
      right: 0;
      margin: auto;
    }

    & > a {
      display: block;
    }
  }

  .embed-responsive {
    width: 100%;
    max-width: 100%;
  }

}



.product-full-slide-thumbs {

  & .custom-slide-item {

    & img {
      width: 100px;
      height: 100px;
      object-fit: cover;
      max-width: 100%;

      @media screen and (max-width: 767px) {
        width: 80px;
        height: 80px;
      }
    }

    & > a {
      display: block;
      border: 2px solid transparent;
      opacity: .7;
      transition: all .3s ease-out;
    }

    &.slick-active {
      & > a {
        border-color: $brand-primary;
        opacity: 1;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .slides {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -5px;
      & > .slide-item {
        padding: 0 5px;
      }
    }
  }
}


.fdb-block .block-title {
  font-weight: 700;
}

.filters {

  padding: 50px 20px;
  background-color: #fafafa;
  border: 15px solid #fff;
  outline: 1px solid #e5e5e5;

  .block-title {
    font-weight: 700;
    color: $brand-primary;
    margin-top: 0;

    &-up {
      text-transform: uppercase;
      font-family: $headings-font-family;
      letter-spacing: 1px;
    }

    &-divider {
      margin-bottom: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid #e5e5e5;
    }
  }
}

.filter-list {

  & > li {
    padding: 5px 0;

    &.active > a {
      color: $brand-primary;
    }
    & > a {
      font-size: 16px;
      color: #555;
      font-weight: 400;
      font-family: 'Open Sans', sans-serif;
    }
  }
}

.right-border {
  border-right: 1px solid #e5e5e5;

  @media screen and (max-width: 991px) {
    border: 0;
  }
}

.form-control:not(textarea) {
  height: 50px;
}

form .btn-primary {
  padding: 12px 40px;
  font-size: 18px;
  font-weight: 700;
}


.sucursales-selected-list {
  & h4 {
    font-family: $headings-font-family;
    font-size: 16px;
    margin-top: 0;
  }

  & small {
    font-size: 14px;
    color: #777;
  }

  & .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.marker-links {

  & > a {
    @media screen and (max-width: 991px) {
      font-size: 14px;
    }
  }
}


.logos-integrantes {
  .slick-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    margin:30px;
    img {
      max-width: 120px;
    }

  }
  .slick-prev:before, .slick-next:before {
    color: $brand-primary;
  }
  @media screen and (max-width: 576px) {
    .slick-prev {
      left:-10px;
      z-index: 999;
    }

    .slick-next {
      right:-10px;
      z-index: 999;
    }
  }
}



.departamentos {
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  .item-dpto {
    margin: 6px 10px;
    font-size: 14px;
    &:hover {
      cursor: pointer;
    }
      &.active {
        background-color: $brand-primary !important;
        color: #fff !important;
      }
    }
}
